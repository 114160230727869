<template>
    <v-container  fluid>
    <v-row dense>
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='editMaterialTypeHeader'/>
                <v-card>
                <!--New Category form -->
                <v-form @submit.prevent="updateMaterialType" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    v-model="material_type_name"
                                    counter="50"
                                    label="Material Type Name"
                                    placeholder="Eg: Timber..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/materials'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <!--Delete Customer button-->
                    <v-btn
                        @click='deleteMaterialType'
                        v-show="this.user_roles_list.flat().includes('work_orders_delete')"
                        color="#ce2458"
                        text
                        x-large
                        :loading="loading_delete"
                    >
                        Delete
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        @click="updateMaterialType"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading_update"
                    >
                        Update
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import FormHeader from '../../../components/Forms/FormHeader'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'EditCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader},

    data(){
        return{
            //Initially set all database values to null
            material_type_id: null,
            material_type_name: null,
            material_type_date_added: null,
            material_type_timestamp: null,
            material_type_created_by_id: null,
            material_type_created_by_name: null,
            loading_update: false,
            loading_delete: false,

            //Defines rules for form validation --> passes properties and rules bound to input fields
            // Valid property has a v-bind to the submit button and toggles disabled state
            valid: false,
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('material_types').where('material_type_id', '==', to.params.material_type_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.material_type_id = doc.data().material_type_id,
                    vm.material_type_name = doc.data().material_type_name                  
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        editMaterialTypeHeader: state => state.editMaterialTypeHeader
    }),
    methods: {
        //Maps database data to template relative to the customer selected in this route
        fetchData () {
        db.collection('material_types').where('material_type_id', '==', this.$route.params.material_type_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.material_type_id = doc.data().material_type_id
                this.material_type_name = doc.data().material_type_name
          })
        })
      },
        //Updates form values in the database
        updateMaterialType () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading_update = true
                db.collection('material_types').where('material_type_id', '==', this.$route.params.material_type_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            material_type_id: this.material_type_id ,
                            material_type_name: this.material_type_name,
                        })
                        .then(() => {
                        //Update material type on categories
                            db.collection('material_categories').where('material_category_type_id', '==', this.material_type_id)
                            .get()
                            .then(snapshots => {
                                if (snapshots.size > 0) {
                                snapshots.forEach(data => {
                                    db.collection('material_categories').doc(data.id).update({ material_category_type: this.material_type_name })
                                })
                            }
                        }),
                        //Update material type on items
                            db.collection('material_items').where('material_item_type_id', '==', this.material_type_id)
                            .get()
                            .then(snapshots => {
                                if (snapshots.size > 0) {
                                snapshots.forEach(data => {
                                    db.collection('material_items').doc(data.id).update({ material_item_type: this.material_type_name })
                                })
                            }
                        }),
                        //Update material type on parts
                            db.collection('parts').where('part_material_type_id', '==', this.material_type_id)
                            .get()
                            .then(snapshots => {
                                if (snapshots.size > 0) {
                                snapshots.forEach(data => {
                                    db.collection('parts').doc(data.id).update({ part_material_type: this.material_type_name })
                                })
                            }
                        }),
                        //Reroute to materials table page after database has been updated
                            this.loading_update = false
                            showSnackbar("Material type updated successfully");
                            this.$router.push('/materials')
                        })
                    })
                })
            }
            
        },
        //Removes material type from database
        deleteMaterialType () {
            if(confirm('Are you sure you want to delete this material type?')){
                this.loading_delete = true
                db.collection('material_types').where('material_type_id', '==', this.$route.params.material_type_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.delete()
                        //Redirects back to customer list page
                        this.loading_delete = false
                        showSnackbar("Material type deleted successfully");
                        this.$router.push('/materials')
                    })
                })
            }
        }
    }
}
</script>