<template>
    <MaterialsTable />
</template>

<script>
import MaterialsTable from '../components/MaterialsTable'
export default {
    name: 'Materials',
    components: {MaterialsTable}
}
</script>