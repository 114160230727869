<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='newMaterialCategoryHeader' />
                <v-card>
                <!--New Material Category form -->
                <v-form @submit.prevent="saveMaterialCategory" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_category_name"
                                    counter="50"
                                    label="Material Category Name"
                                    placeholder="Eg: Oak..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    v-model="material_category_type"
                                    label="Material Type"
                                    :items="material_types"
                                    item-text="material_type_name"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_category_prefix"
                                    counter="50"
                                    label="Category Prefix"
                                    placeholder="Eg: VLV"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <!--
                                A hidden field that lets us capture the material type ID
                                This is used to auto apply material type name changes to categories
                            -->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    readonly
                                    v-model="material_category_type_id"
                                    :value="getMaterialTypeDetails()"
                                    label="Part #"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                    style="visibility: hidden"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/materials'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveMaterialCategory"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            material_category_id: null,
            material_category_name: null,
            material_category_type: null,
            material_category_type_id: null,
            material_category_prefix: null,
            material_category_counter: null,
            material_category_date_added: null,
            material_category_timestamp: null,
            material_category_created_by_id: null,
            material_category_created_by_name: null,
            loading: false,
            loading_material_type: false,
            material_types: [],
        }
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        newMaterialCategoryHeader: state => state.newMaterialCategoryHeader,
    }),
    created(){
        //Captures user that created the sales order
        this.material_category_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.material_category_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.material_category_created_by_name = doc.data().displayName
            })
        }),
        this.loading_material_type = true
        db.collection('material_types').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              //Pushes the customers name to the empty customers array in 'data' above
              this.material_types.push(data)
              this.loading_material_type = false
            })
          })
    },
    methods: {
        routeToTable(){
            //Reroute to datatable
            this.loading = false
            showSnackbar("New material category added successfully")
            this.$router.push('/materials')
        },
        saveMaterialCategory () {
            //Form validation --> ensures required fields aren't blank before continuing further           
            if(this.$refs.form.validate()){
                this.loading = true
                //Sets the category_id == to the document id in database
                var newDocRef = db.collection('material_categories').doc();
                newDocRef.set({
                    //Populated by the v-model values in the form
                    material_category_id: newDocRef.id,
                    material_category_name: this.material_category_name,
                    material_category_type: this.material_category_type,
                    material_category_type_id: this.material_category_type_id,
                    material_category_prefix: this.material_category_prefix,
                    material_category_counter: 1,
                    material_category_date_added: new Date().toISOString().slice(0, 10),
                    material_category_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    material_category_created_by_id: this.material_category_created_by_id,
                    material_category_created_by_name: this.material_category_created_by_name,
                })
                .then(() => {
                    //Reroute to datatable
                    this.routeToTable()
                })
            }
        },
        //  This function is called on a hidden form field that allows us to retrieve and assign
        //  material_type_id
        getMaterialTypeDetails(){
            db.collection('material_types').where('material_type_name', '==', this.material_category_type).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
               this.material_category_type = data.material_type_name
               this.material_category_type_id = data.material_type_id
            })
          })
        },
    }
}
</script>