<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='newMaterialItemHeader' />
                <v-card>
                <!--New Material Type form -->
                <v-form @submit.prevent="saveMaterialItem" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    v-model="material_item_category"
                                    label="Material Category"
                                    :items="material_categories"
                                    item-text="material_category_name"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_item_code"
                                    readonly
                                    counter="50"
                                    label="Item Code"
                                    placeholder="Eg: PLY-0001..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_item_name"
                                    counter="50"
                                    label="Item Name"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_item_display_name"
                                    counter="50"
                                    label="Display Name"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                    hint="The description that appears on assembly part lists"
                                    persistent-hint
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6"  v-show="false">
                                <v-text-field
                                    readonly
                                    v-model="material_item_type"
                                    :value="getMaterialType()"
                                    label="Material Type"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_item_prefix"
                                    label="Item Prefix"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    v-model="material_item_unit_of_measurement"
                                    label="Unit of Measurement"
                                    :items="units_of_measurement"
                                    item-text="unit_of_measurement_symbol"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/materials'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveMaterialItem"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            material_item_id: null,
            material_item_code: null,
            material_item_name: null,
            material_item_display_name: null,
            material_item_category: null,
            material_item_type: null,
            material_item_type_id: null,
            material_item_prefix: null,
            material_item_category_prefix: null,
            material_item_category_id: null,
            material_item_unit_of_measurement: null,
            material_item_date_added: null,
            material_item_timestamp: null,
            material_item_created_by_id: null,
            material_item_created_by_name: null,
            material_category_counter: null,
            material_item_counter: null,
            material_category_id: null,
            loading: false,
            loading_material_category: false,
            material_categories: [],
            units_of_measurement: [],
        }
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        newMaterialItemHeader: state => state.newMaterialItemHeader,
    }),
    created(){
        //Captures user that created the sales order
        this.material_item_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.material_item_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.material_item_created_by_name = doc.data().displayName
            })
        }),
        this.loading_material_category = true
        db.collection('material_categories').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              //Pushes material categories info to empty array above
              this.material_categories.push(data)
              this.loading_material_category = false
            })
          })
          db.collection('units_of_measurement').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              //Pushes the units of measureent to empty array above
              this.units_of_measurement.push(data)
            })
          })
    },
    methods: {
        //Reroute to data table
        routeToTable(){
            this.loading = false
            showSnackbar("New material item added successfully");
            this.$router.push('/materials')
        },
        //Update Material Category Counter
        increaseMaterialCategoryCounter(){
            const db = firebase.firestore()
            const increment = firebase.firestore.FieldValue.increment(1)
            const materialCategoryCounter = db.collection('material_categories').doc(this.material_category_id)
            materialCategoryCounter.update({ material_category_counter: increment })
        },
        saveMaterialItem () {
            //Form validation --> ensures required fields aren't blank before continuing further           
            if(this.$refs.form.validate()){
                this.loading = true
                //Sets the category_id == to the document id in database
                var newDocRef = db.collection('material_items').doc();
                newDocRef.set({
                    //Populated by the v-model values in the form
                    material_item_id: newDocRef.id,
                    material_item_code: this.material_item_code,
                    material_item_name: this.material_item_name,
                    material_item_display_name: this.material_item_display_name,
                    material_item_category: this.material_item_category,
                    material_item_type: this.material_item_type,
                    material_item_type_id: this.material_item_type_id,
                    material_item_prefix: this.material_item_prefix,
                    material_item_category_prefix: this.material_item_category_prefix,
                    material_item_category_id: this.material_item_category_id,
                    material_item_counter: 1,
                    material_item_unit_of_measurement: this.material_item_unit_of_measurement,
                    material_item_date_added: new Date().toISOString().slice(0, 10),
                    material_item_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    material_item_created_by_id: this.material_item_created_by_id,
                    material_item_created_by_name: this.material_item_created_by_name,
                })
                //Reroute to customers list after database is updated
                .then(() => {
                    //Update Material Category Counter
                    this.increaseMaterialCategoryCounter()
                    //Reroute to data table
                    this.routeToTable()
                })
            }
        },
        getMaterialType(){
            db.collection('material_categories').where('material_category_name', '==', this.material_item_category).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              //Assigns category data to related material item fields
               this.material_item_type = data.material_category_type
               this.material_item_type_id = data.material_category_type_id
               this.material_item_category = data.material_category_name
               this.material_item_category_prefix = data.material_category_prefix
               this.material_item_category_id = data.material_category_id
               //Format item code
               const count = data.material_category_counter   
                    if(count < 10){
                        this.material_item_code = `${data.material_category_prefix}-000${data.material_category_counter}`
                    }else if (count >= 10 && count < 100){
                        this.material_item_code = `${data.material_category_prefix}-00${data.material_category_counter}`
                    }else if (count >= 100 && count < 1000){
                        this.material_item_code = `${data.material_category_prefix}-0${data.material_category_counter}`
                    }else {
                        this.material_item_code = `${data.material_category_prefix}-${data.material_category_counter}`
                    }   
               return this.material_category_id = data.material_category_id
            })
          })
        },
    }
}
</script>