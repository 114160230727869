<template>
    <v-container  fluid>
    <v-row dense>
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='editMaterialItemHeader'/>
                <v-card>
                <!--New Category form -->
                <v-form @submit.prevent="updateMaterialItem" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    v-model="material_item_category"
                                    label="Material Category"
                                    :items="material_categories"
                                    item-text="material_category_name"
                                    @change="updateMaterialCategory()"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_item_code"
                                    readonly
                                    counter="50"
                                    label="Item Code"
                                    placeholder="Eg: PLY-0001..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_item_name"
                                    counter="50"
                                    label="Item Name"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_item_display_name"
                                    counter="50"
                                    label="Display Name"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                    hint="The description that appears on assembly part lists"
                                    persistent-hint
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" v-show="false">
                                <v-text-field
                                    readonly
                                    v-model="material_item_type"
                                    :value="getMaterialType()"
                                    label="Material Type"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_item_prefix"
                                    label="Item Prefix"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    v-model="material_item_unit_of_measurement"
                                    label="Unit of Measurement"
                                    :items="units_of_measurement"
                                    item-text="unit_of_measurement_symbol"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/materials'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <!--Delete Customer button-->
                    <v-btn
                        @click='deleteMaterialItem'
                        v-show="this.user_roles_list.flat().includes('work_orders_delete')"
                        color="#ce2458"
                        text
                        x-large
                        :loading="loading_delete"
                    >
                        Delete
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        @click="updateMaterialItem"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading_update"
                    >
                        Update
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import FormHeader from '../../../components/Forms/FormHeader'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'EditCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader},

    data(){
        return{
            //Initially set all database values to null
            valid: null,
            material_item_id: null,
            material_item_code: null,
            material_item_name: null,
            material_item_display_name: null,
            material_item_category: null,
            material_item_type: null,
            material_item_type_id: null,
            material_item_prefix: null,
            material_item_category_prefix: null,
            material_item_category_id: null,
            material_item_unit_of_measurement: null,
            material_item_date_added: null,
            material_item_timestamp: null,
            material_item_created_by_id: null,
            material_item_created_by_name: null,
            trigger_material_category_counter: false,
            material_category_id: null,
            loading: false,
            loading_material_category: false,
            material_categories: [],
            units_of_measurement: [],
            loading_update: false,
            loading_delete: false,

            pathHome: '/materials',
            updateMessage: 'Material item updated successfully',
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('material_items').where('material_item_id', '==', to.params.material_item_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.material_item_id = doc.data().material_item_id,
                    vm.material_item_code = doc.data().material_item_code,
                    vm.material_item_name = doc.data().material_item_name,   
                    vm.material_item_display_name = doc.data().material_item_display_name,              
                    vm.material_item_category = doc.data().material_item_category,  
                    vm.material_item_type = doc.data().material_item_type, 
                    vm.material_item_type_id = doc.data().material_item_type_id,
                    vm.material_item_prefix = doc.data().material_item_prefix, 
                    vm.material_item_category_prefix = doc.data().material_item_category_prefix,
                    vm.material_item_category_id = doc.data().material_item_category_id,
                    vm.material_item_unit_of_measurement = doc.data().material_item_unit_of_measurement,  
                    vm.material_item_date_added = doc.data().material_item_date_added
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        editMaterialItemHeader: state => state.editMaterialItemHeader
    }),
    created(){
        this.loading_material_category = true
        db.collection('material_categories').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              //Pushes the customers name to the empty customers array in 'data' above
              this.material_categories.push(data)
              this.loading_material_category = false
            })
          })
          db.collection('units_of_measurement').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              //Pushes the customers name to the empty customers array in 'data' above
              this.units_of_measurement.push(data)
            })
          })
    },
    methods: {
        //Maps database data to template relative to the customer selected in this route
        fetchData () {
        db.collection('material_items').where('material_item_id', '==', this.$route.params.material_item_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
                this.material_item_id = doc.data().material_item_id;
                this.material_item_name = doc.data().material_item_name;
                this.material_item_display_name = doc.data().material_item_display_name;
                this.material_item_code = doc.data().material_item_code;
                this.material_item_category = doc.data().material_item_category;
                this.material_item_type = doc.data().material_item_type;
                this.material_item_type_id = doc.data().material_item_type_id;
                this.material_item_prefix = doc.data().material_item_prefix;
                this.material_item_category_prefix = doc.data().material_item_category_prefix;
                this.material_item_category_id = doc.data().material_item_category_id;
                this.material_item_unit_of_measurement = doc.data().material_item_unit_of_measurement;
                this.material_item_date_added = doc.data().material_item_date_added;
          })
        })
      },
        routeToTable(){
            showSnackbar(this.updateMessage);
            this.$router.push(this.pathHome)
        },
        //Updates form values in the database
        updateMaterialItem () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading_update = true
                db.collection('material_items').where('material_item_id', '==', this.$route.params.material_item_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            material_item_id: this.material_item_id,
                            material_item_name: this.material_item_name,
                            material_item_display_name: this.material_item_display_name,
                            material_item_code: this.material_item_code,
                            material_item_category: this.material_item_category,
                            material_item_type: this.material_item_type,
                            material_item_type_id: this.material_item_type_id,
                            material_item_prefix: this.material_item_prefix,
                            material_item_category_prefix: this.material_item_category_prefix,
                            material_item_category_id: this.material_item_category_id,
                            material_item_unit_of_measurement: this.material_item_unit_of_measurement,
                            material_item_date_added: this.material_item_date_added,


                            //REFACTORING EXPERIMENT
                                // there is a list of properties to assign
                                // loop through all the properties (contained in an array)
                                // construct property name from computed property + array item name
                                // assign value to property
                                    // value == this. [property name]

                            //  let base = 'material_item_'
                            //  ['id', 'name', 'code', 'category']
                            //  [base].id






                        })
                        //Reroute to materials table page after database has been updated
                        .then(() => {
                            //Update material items on parts
                            db.collection('parts').where('part_material_item_id', '==', this.material_item_id)
                            .get()
                            .then(snapshots => {
                                if (snapshots.size > 0) {
                                snapshots.forEach(data => {
                                    db.collection('parts').doc(data.id).update({ 
                                        part_material_item_name: this.material_item_name,
                                        part_material_item_display_name: this.material_item_display_name,
                                        part_material_item_code: this.material_item_code
                                        })
                                    })
                                }
                            })
                            //Update material category counter if the category has been changed
                            if(this.trigger_material_category_counter === true){
                                const db = firebase.firestore();
                                //Update Material Category Counter
                                const increment = firebase.firestore.FieldValue.increment(1);
                                const materialCategoryCounter = db.collection('material_categories').doc(this.material_category_id);
                                //Increments work order counter
                                materialCategoryCounter.update({ material_category_counter: increment });

                                this.routeToTable()
                            }
                            else{
                                this.routeToTable()
                            }
                        })
                    })
                })
            }
            
        },
        //Removes material item from database
        deleteMaterialItem () {
            if(confirm('Are you sure you want to delete this material item?')){
                this.loading_delete = true
                db.collection('material_items').where('material_item_id', '==', this.$route.params.material_item_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.delete()
                        //Redirects back to customer list page
                        this.routeToTable()
                    })
                })
            }
        },
        getMaterialType(){
            db.collection('material_categories').where('material_category_name', '==', this.material_item_category).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              //Assigns category data to related material item fields
               this.material_item_type = data.material_category_type
               this.material_item_category = data.material_category_name
               this.material_item_type_id = data.material_category_type_id
               this.material_item_category_prefix = data.material_category_prefix
               this.material_item_category_id = data.material_category_id
               return this.material_category_id = data.material_category_id
            })
          })
        },
        //Increments the material category counter if the category is changed by the user
        updateMaterialCategory(){
            this.trigger_material_category_counter = true
            if(this.trigger_material_category_counter === true){
                    db.collection('material_categories').where('material_category_name', '==', this.material_item_category).get().then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                    const data = doc.data()
                    //Pushes the customers name to the empty customers array in 'data' above
                    console.log(data)
                    return this.material_item_code = `${data.material_category_prefix}-000${data.material_category_counter}`
                    })
                })
            }
        }
    }
}
</script>