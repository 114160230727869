<template>
    <div>
        <UnitsOfMeasurementTable />
    </div>
</template>

<script>
import UnitsOfMeasurementTable from '../components/UnitsOfMeasurementTable'
export default {
    name: 'UnitsOfMeasurement',
    components: {UnitsOfMeasurementTable}
}
</script>