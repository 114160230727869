<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='newUnitOfMeasurementHeader' />
                <v-card>
                <!--New Material Type form -->
                <v-form @submit.prevent="saveUnitOfMeasurement" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="unit_of_measurement_name"
                                    counter="50"
                                    label="Name"
                                    placeholder="Eg: Milimeter"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="unit_of_measurement_symbol"
                                    counter="50"
                                    label="Symbol"
                                    placeholder="Eg: mm"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/materials'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveUnitOfMeasurement"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,

            unit_of_measurement_id: null,
            unit_of_measurement_name: null,
            unit_of_measurement_symbol: null,
            unit_of_measurement_date_added: null,
            unit_of_measurement_timestamp: null,
            unit_of_measurement_created_by_id: null,
            unit_of_measurement_created_by_name: null,
            loading: false,
        }
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        newUnitOfMeasurementHeader: state => state.newUnitOfMeasurementHeader
    }),
    created(){
        //Captures user that created the sales order
        this.unit_of_measurement_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.unit_of_measurement_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.unit_of_measurement_created_by_name = doc.data().displayName
            })
        })
    },
    methods: {
        saveUnitOfMeasurement () {
            //Form validation --> ensures required fields aren't blank before continuing further           
            if(this.$refs.form.validate()){
                this.loading = true
                //Sets the category_id == to the document id in database
                var newDocRef = db.collection('units_of_measurement').doc();
                newDocRef.set({
                    //Populated by the v-model values in the form
                    unit_of_measurement_id: newDocRef.id,
                    unit_of_measurement_name: this.unit_of_measurement_name,
                    unit_of_measurement_symbol: this.unit_of_measurement_symbol,
                    unit_of_measurement_date_added: new Date().toISOString().slice(0, 10),
                    unit_of_measurement_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    unit_of_measurement_created_by_id: this.unit_of_measurement_created_by_id,
                    unit_of_measurement_created_by_name: this.unit_of_measurement_created_by_name,
                })
                //Reroute to customers list after database is updated
                .then(() => {
                    this.loading = false
                    showSnackbar("New unit of measurement added successfully");
                    this.$router.push('/units-of-measurement')
                })
            }
        }
    }
}
</script>