<template>
  <div>
    <v-container>
      <v-row class="mb-8">
        <v-col lg="12">
          <v-card-title>
            <h2>Materials</h2>
            <!--Skeleton button-->
            <v-skeleton-loader
              :loading="loading_user"
              type="button"
              v-if="firstLoad"
              class="ml-10"
            >
            </v-skeleton-loader>
            <NewMaterialButton v-if="!firstLoad" />
            <v-spacer />
          </v-card-title>
        </v-col>
      </v-row>

      <!--Navigation Tabs-->
      <v-sheet elevation="4">
        <v-tabs background-color="#6666cf" center-active dark>
          <v-tab>Items</v-tab>
          <v-tab>Categories</v-tab>
          <v-tab>Types</v-tab>

          <!--Data tables-->
          <!--Material Items-->
          <v-tab-item>
            <!--Table skeleton loader-->
            <v-row v-if="firstLoad">
              <v-col>
                <v-sheet elevation="4">
                  <v-skeleton-loader
                    :loading="loading_user"
                    type="table"
                    max-height="290"
                  >
                  </v-skeleton-loader>
                </v-sheet>
              </v-col>
            </v-row>
            <!--End of skeleton loader-->
            <v-row
              v-if="!firstLoad"
              v-show="this.user_roles_list.flat().includes('work_orders_read')"
            >
              <v-col>
                <!--Materials data table-->
                <v-card>
                  <v-card-title>
                    Material Items<v-spacer></v-spacer>
                  </v-card-title>
                  <v-data-table
                    :headers="materialItemsHeaders"
                    :items="filteredItems"
                    :search="search"
                    :items-per-page="100"
                  >
                    <template v-slot:top>
                      <v-container fluid>
                        <v-row>
                          <v-col lg="3" md="3">
                            <v-autocomplete
                              :items="material_types"
                              v-model="materialCategorySelection"
                              label="Material Types"
                              item-text="material_type_name"
                              outlined
                              flat
                              solo
                              dense
                              append-icon="mdi-filter-variant"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-spacer />
                          <!--Search Bar-->
                          <v-col lg="6" md="6">
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                              clearable
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>

                    <!--Edit Material Item icon button-->
                    <template
                      v-slot:item.material_item_id="{ item }"
                      v-if="
                        this.user_roles_list
                          .flat()
                          .includes('work_orders_write')
                      "
                    >
                      <v-btn
                        :to="{
                          name: 'edit-material-item',
                          params: { material_item_id: item.material_item_id },
                        }"
                        color="#3d2cdd"
                        icon
                        small
                        ><v-icon small>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <!--View material item-->
                    <template v-slot:item.material_item_code="{ item }">
                      <router-link
                        :to="{
                          name: 'view-material-item',
                          params: { material_item_id: item.material_item_id },
                        }"
                      >
                        {{ item.material_item_code }}
                      </router-link>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <!--Material Categories-->
            <!--Table skeleton loader-->
            <v-row v-if="firstLoad">
              <v-col>
                <v-sheet elevation="4">
                  <v-skeleton-loader
                    :loading="loading_user"
                    type="table"
                    max-height="290"
                  >
                  </v-skeleton-loader>
                </v-sheet>
              </v-col>
            </v-row>
            <!--End of skeleton loader-->
            <v-row
              v-if="!firstLoad"
              v-show="this.user_roles_list.flat().includes('work_orders_read')"
            >
              <v-col>
                <!--Materials data table-->
                <v-card>
                  <v-card-title>
                    Material Categories<v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :headers="materialCategoriesHeaders"
                    :items="material_categories"
                    :search="search"
                    :items-per-page="100"
                  >
                    <!--Edit Material Category icon button-->
                    <template
                      v-slot:item.material_category_id="{ item }"
                      v-if="
                        this.user_roles_list
                          .flat()
                          .includes('work_orders_write')
                      "
                    >
                      <v-btn
                        :to="{
                          name: 'edit-material-category',
                          params: {
                            material_category_id: item.material_category_id,
                          },
                        }"
                        color="#3d2cdd"
                        icon
                        small
                        ><v-icon small>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>

          <!--Material Types-->
          <v-tab-item>
            <!--Material Categories-->
            <!--Table skeleton loader-->
            <v-row v-if="firstLoad">
              <v-col>
                <v-sheet elevation="4">
                  <v-skeleton-loader
                    :loading="loading_user"
                    type="table"
                    max-height="290"
                  >
                  </v-skeleton-loader>
                </v-sheet>
              </v-col>
            </v-row>
            <!--End of skeleton loader-->
            <v-row
              v-if="!firstLoad"
              v-show="this.user_roles_list.flat().includes('work_orders_read')"
            >
              <v-col>
                <!--Materials data table-->
                <v-card>
                  <v-card-title>
                    Material Types<v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :headers="materialTypesHeaders"
                    :items="material_types"
                    :search="search"
                    :items-per-page="100"
                  >
                    <!--Edit Material Type icon button-->
                    <template
                      v-slot:item.material_type_id="{ item }"
                      v-if="
                        this.user_roles_list
                          .flat()
                          .includes('work_orders_write')
                      "
                    >
                      <v-btn
                        :to="{
                          name: 'edit-material-type',
                          params: { material_type_id: item.material_type_id },
                        }"
                        color="#3d2cdd"
                        icon
                        small
                        ><v-icon small>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import db from "../../../components/firebaseInit"; //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import NewMaterialButton from "../components/NewMaterialButton";
import store from "../store/store";
import { mapState } from "vuex";

export default {
  name: "WorkOrdersTable",
  mixins: [mixin_UserRoles],
  components: { NewMaterialButton },
  data() {
    return {
      search: "",
      material_items: [],
      material_categories: [],
      material_types: [],
      materialCategorySelection: "",
    };
  },
  store,
  computed: mapState({
    //This is where data is retrieved from the Vuex state
    materialItemsHeaders: (state) => state.materialItemsHeaders,
    materialCategoriesHeaders: (state) => state.materialCategoriesHeaders,
    materialTypesHeaders: (state) => state.materialTypesHeaders,
    filteredItems() {
      return this.material_items.filter((i) => {
        return (
          this.materialCategorySelection === "" ||
          i.material_item_type === this.materialCategorySelection
        );
      });
    },
  }),
  created() {
    //Get material items from DB
    db
      .collection("material_items")
      .orderBy("material_item_type")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          //Pushes the customers name to the empty customers array in 'data' above
          this.material_items.push(data);
        });
      }),
      //Get material categories from DB
      db
        .collection("material_categories")
        .orderBy("material_category_type")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            //Pushes the products name to the empty customers array in 'data' above
            this.material_categories.push(data);
          });
        }),
      //Get material types from DB
      db
        .collection("material_types")
        .orderBy("material_type_name")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            //Pushes the products name to the empty customers array in 'data' above
            this.material_types.push(data);
          });
        });
  },
};
</script>