<template>
    <div>
         <v-container>
            <v-row class="mb-8">
                <v-col lg="12">
                    <v-card-title>
                        <h2>Units of Measurement</h2>
                        <!--Skeleton button-->
                        <v-skeleton-loader
                          :loading="loading_user"
                          type="button"
                          v-if="firstLoad"
                          class="ml-10"
                        >
                        </v-skeleton-loader>
                        <!--Add category button-->
                        <NewUnitOfMeasurementButton
                          v-if="!firstLoad"
                          v-show="this.user_roles_list.flat().includes('work_orders_write')"
                        />
                    </v-card-title>
                </v-col>
            </v-row>
            <!--Table skeleton loader-->
              <v-row v-if="firstLoad">
                <v-col>
                  <v-sheet elevation="4">
                    <v-skeleton-loader :loading="loading_user" type="table" max-height="500">
                    </v-skeleton-loader>
                  </v-sheet>
                </v-col>
              </v-row>
              <!--End of skeleton loader-->
            <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('products_read')">
                <v-col>
                    <v-sheet elevation="4">
                        <!--Customer data table-->    
                        <v-data-table
                            :headers="unitsOfMeasurementHeaders"
                            :items="units_of_measurement"
                            :search="search"
                            :items-per-page="100"
                        >
                        <!--Edit Material Type icon button-->
                            <template
                                v-slot:item.unit_of_measurement_id={item}
                                v-if="this.user_roles_list.flat().includes('work_orders_write')"
                            >
                                <v-btn
                                    :to="{ name: 'edit-unit-of-measurement', params: { unit_of_measurement_id: item.unit_of_measurement_id }}"
                                    color="#3d2cdd"
                                    icon
                                    small
                                ><v-icon small>mdi-pencil</v-icon></v-btn>
                            </template>
                        </v-data-table>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import NewUnitOfMeasurementButton from './NewUnitOfMeasurementButton'
import store from '../store/store'
import {mapState} from 'vuex'
export default {
    name: 'CategoriesTable',
    mixins: [mixin_UserRoles],
    components: {NewUnitOfMeasurementButton},
    data () {
      return {
        pagination: {},
        search: '',
        units_of_measurement: [],

      }
    },
    // Gets categorie from firebase datatbase
    created(){
      db.collection('units_of_measurement').get().then(querySnapshot => {
        this.categories = []
        querySnapshot.forEach(doc => {
          const data = {
            'id': doc.id,
            'unit_of_measurement_id': doc.data().unit_of_measurement_id,
            'unit_of_measurement_name': doc.data().unit_of_measurement_name,
            'unit_of_measurement_symbol': doc.data().unit_of_measurement_symbol,            
          }
          //Pushes the categories objects to the empty categories array above
          this.units_of_measurement.push(data)
        })
      })
    },
    store,
    computed: mapState({
        //This is where data is retrieved from the Vuex state
        unitsOfMeasurementHeaders: state => state.unitsOfMeasurementHeaders,
    }), 
}
</script>