<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='newMaterialTypeHeader' />
                <v-card>
                <!--New Material Type form -->
                <v-form @submit.prevent="saveMaterialType" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    v-model="material_type_name"
                                    counter="50"
                                    label="Material Type Name"
                                    placeholder="Eg: Timber..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/materials'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveMaterialType"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            material_type_id: null,
            material_type_name: null,
            material_type_date_added: null,
            material_type_timestamp: null,
            material_type_created_by_id: null,
            material_type_created_by_name: null,
            loading: false,
        }
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        newMaterialTypeHeader: state => state.newMaterialTypeHeader
    }),
    created(){
        //Captures user that created the sales order
        this.material_type_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.material_type_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.material_type_created_by_name = doc.data().displayName
            })
        })
    },
    methods: {
        rerouteToTable(){
            //Reroute to datatable
            this.loading = false
            showSnackbar("New material type added successfully");
            this.$router.push('/materials')
        },
        saveMaterialType () {
            //Form validation --> ensures required fields aren't blank before continuing further           
            if(this.$refs.form.validate()){
                this.loading = true
                //Sets the category_id == to the document id in database
                var newDocRef = db.collection('material_types').doc();
                newDocRef.set({
                    //Populated by the v-model values in the form
                    material_type_id: newDocRef.id,
                    material_type_name: this.material_type_name,
                    material_type_date_added: new Date().toISOString().slice(0, 10),
                    material_type_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    material_type_created_by_id: this.material_type_created_by_id,
                    material_type_created_by_name: this.material_type_created_by_name,
                })
                .then(() => {
                    //Reroute to datatable
                    this.rerouteToTable()
                })
            }
        }
    }
}
</script>