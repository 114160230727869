<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='editMaterialCategoryHeader' />
                <v-card>
                <!--New Material Category form -->
                <v-form @submit.prevent="updateMaterialCategory" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_category_name"
                                    counter="50"
                                    label="Material Category Name"
                                    placeholder="Eg: Oak..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    v-model="material_category_type"
                                    label="Material Type"
                                    :items="material_types"
                                    item-text="material_type_name"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="material_category_prefix"
                                    counter="50"
                                    label="Category Prefix"
                                    placeholder="Eg: VLV"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!--
                                A hidden field that lets us capture the material type ID
                                This is used to auto apply material type name changes to categories
                            -->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    readonly
                                    v-model="material_category_type_id"
                                    :value="getMaterialTypeDetails()"
                                    label="Part #"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                    style="visibility: hidden"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/materials'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <!--Delete Customer button-->
                    <v-btn
                        @click='deleteMaterialCategory'
                        v-show="this.user_roles_list.flat().includes('work_orders_delete')"
                        color="#ce2458"
                        text
                        x-large
                        :loading="loading_delete"
                    >
                        Delete
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        @click="updateMaterialCategory"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading_update"
                    >
                        Update
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            material_category_id: null,
            material_category_name: null,
            material_category_type: null,
            material_category_type_id: null,
            material_category_prefix: null,
            material_category_date_added: null,
            material_category_timestamp: null,
            material_category_created_by_id: null,
            material_category_created_by_name: null,
            loading: false,
            loading_material_type: false,
            loading_update: false,
            loading_delete: false,
            material_types: [],
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('material_categories').where('material_category_id', '==', to.params.material_category_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.material_category_id = doc.data().material_category_id,
                    vm.material_category_name = doc.data().material_category_name,
                    vm.material_category_type = doc.data().material_category_type,
                    vm.material_category_type_id = doc.data().material_category_type_id,
                    vm.material_category_prefix = doc.data().material_category_prefix                 
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        editMaterialCategoryHeader: state => state.editMaterialCategoryHeader
    }),
    created(){
        //Captures user that created the sales order
        this.material_category_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.material_category_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.material_category_created_by_name = doc.data().displayName
            })
        }),
        this.loading_material_type = true
        db.collection('material_types').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            const data = doc.data()
              //Pushes the material type name to the empty customers array in 'data' above
              this.material_types.push(data)
              this.loading_material_type = false
            })
          })
    },
    methods: {
        //Maps database data to template relative to the customer selected in this route
        fetchData () {
        db.collection('material_categories').where('material_category_id', '==', this.$route.params.material_category_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.material_category_id = doc.data().material_category_id
                this.material_category_name = doc.data().material_category_name
                this.material_category_type = doc.data().material_category_type
                this.material_category_type_id = doc.data().material_category_type_id
                this.material_category_prefix = doc.data().material_category_prefix
          })
        })
      },
      //update material category data on related items
      updateItemData(){
          //Update material category on items
            db.collection('material_items').where('material_item_category_id', '==', this.material_category_id)
            .get()
            .then(snapshots => {
                if (snapshots.size > 0) {
                snapshots.forEach(data => {
                    db.collection('material_items').doc(data.id).update({ 
                        material_item_category: this.material_category_name,
                        material_item_category_prefix: this.material_category_prefix,
                    })
                })
            }
        })
      },
      //update material category data on related parts
      updatePartData(){
          db.collection('parts').where('part_material_category_id', '==', this.material_category_id)
                .get()
                .then(snapshots => {
                    if (snapshots.size > 0) {
                    snapshots.forEach(data => {
                        db.collection('parts').doc(data.id).update({ 
                            part_material_category: this.material_category_name,
                        })
                    })
                }
            })
        },
        //Reroute to materials table page after database has been updated
        routeToTable(){
            this.loading_update = false
            showSnackbar("Material category updated successfully");
            this.$router.push('/materials')
        },
      
      //Updates form values in the database
        updateMaterialCategory () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading_update = true
                db.collection('material_categories').where('material_category_id', '==', this.$route.params.material_category_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            material_category_id: this.material_category_id ,
                            material_category_name: this.material_category_name,
                            material_category_type: this.material_category_type,
                            material_category_type_id: this.material_category_type_id,
                            material_category_prefix: this.material_category_prefix,
                        })
                        .then(() => {
                            //Update category data on items
                            this.updateItemData()
                            //Update material category on parts
                            this.updatePartData()
                            //Route back to datatable
                            this.routeToTable()
                        })
                    })
                })
            }
            
        },
        //Removes material type from database
        deleteMaterialCategory () {
            if(confirm('Are you sure you want to delete this material category?')){
                this.loading_delete = true
                db.collection('material_categories').where('material_category_id', '==', this.$route.params.material_category_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.delete()
                        //Redirects back to customer list page
                        this.loading_delete = false
                        showSnackbar("Material category deleted successfully");
                        this.$router.push('/materials')
                    })
                })
            }
        },
        //  This function is called on a hidden form field that allows us to retrieve and assign
        //  material_type_id
        getMaterialTypeDetails(){
            db.collection('material_types').where('material_type_name', '==', this.material_category_type).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
               this.material_category_type = data.material_type_name
               this.material_category_type_id = data.material_type_id
            })
          })
        },
    }
}
</script>